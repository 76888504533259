@tailwind base;
@tailwind components;
@tailwind utilities;

  .center {
    text-align: center;
    width: 100%;
}

#menu-toggle:checked + #menu {
  display: block;
}