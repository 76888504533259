*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

:root, [data-theme] {
  background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --p: 270 85% 79%;
  --pf: 270 85% 64%;
  --sf: 269 97% 68%;
  --af: 174 60% 41%;
  --nf: 219 14% 22%;
  --b2: 0 0% 90%;
  --b3: 0 0% 81%;
  --bc: 0 0% 20%;
  --pc: 270 100% 16%;
  --sc: 269 100% 17%;
  --ac: 174 100% 10%;
  --nc: 219 60% 86%;
  --inc: 198 100% 12%;
  --suc: 158 100% 10%;
  --wac: 43 100% 11%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --s: 269 97% 85%;
  --a: 174 60% 51%;
  --n: 219 14% 28%;
  --b1: 0 0% 100%;
  --in: 198 93% 60%;
  --su: 158 64% 52%;
  --wa: 43 96% 56%;
  --er: 0 91% 71%;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  height: 3rem;
  min-height: 3rem;
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled] {
  pointer-events: none;
}

.btn.loading, .btn.loading:hover {
  pointer-events: none;
}

.btn.loading:before {
  height: 1rem;
  width: 1rem;
  content: "";
  border-width: 2px;
  border-color: #0000 currentColor currentColor #0000;
  border-radius: 9999px;
  margin-right: .5rem;
  animation: spin 2s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .btn.loading:before {
    animation: spin 10s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-group > input[type="radio"].btn {
  appearance: none;
}

.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.card-body :where(p) {
  flex-grow: 1;
}

.card-actions {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.card.image-full > .card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  position: relative;
}

.checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 0;
}

.footer {
  width: 100%;
  grid-auto-flow: row;
  place-items: start;
  gap: 2.5rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: grid;
}

.footer > * {
  place-items: start;
  gap: .5rem;
  display: grid;
}

@media (min-width: 48rem) {
  .footer {
    grid-auto-flow: column;
  }

  .footer-center {
    grid-auto-flow: row dense;
  }
}

.label {
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.hero {
  width: 100%;
  background-position: center;
  background-size: cover;
  place-items: center;
  display: grid;
}

.hero > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.hero-content {
  z-index: 0;
  max-width: 80rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.input {
  height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-group > *, .input-group > .input {
  border-radius: 0;
}

.link {
  cursor: pointer;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.menu {
  flex-direction: column;
  display: flex;
}

.menu.horizontal {
  flex-direction: row;
  display: inline-flex;
}

.menu.horizontal :where(li) {
  flex-direction: row;
}

.menu :where(li) {
  flex-flow: column wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.menu :where(li:not(.menu-title)) > :where(:not(ul)) {
  display: flex;
}

.menu :where(li:not(.disabled):not(.menu-title)) > :where(:not(ul)) {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  outline-offset: 2px;
  outline: 2px solid #0000;
  align-items: center;
}

.menu > :where(li > :not(ul):focus) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu > :where(li.disabled > :not(ul):focus) {
  cursor: auto;
}

.menu > :where(li) :where(ul) {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.menu > :where(li) > :where(ul) {
  top: initial;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  display: none;
  position: absolute;
  left: 100%;
}

.menu > :where(li:hover) > :where(ul) {
  display: flex;
}

.menu > :where(li:focus) > :where(ul) {
  display: flex;
}

.btn-outline.btn-primary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-primary .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-primary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .95));
  animation: none;
}

.btn:hover, .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity));
}

.btn:focus-visible {
  outline: 2px solid hsl(var(--nf));
  outline-offset: 2px;
}

.btn-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-primary:hover, .btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
}

.btn-primary:focus-visible {
  outline: 2px solid hsl(var(--p));
}

.btn.glass:hover, .btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn.glass:focus-visible {
  outline: 2px solid 0 0 2px currentColor;
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-disabled, .btn-disabled:hover, .btn[disabled], .btn[disabled]:hover {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btn.loading.btn-square:before, .btn.loading.btn-circle:before {
  margin-right: 0;
}

.btn.loading.btn-xl:before, .btn.loading.btn-lg:before {
  height: 1.25rem;
  width: 1.25rem;
}

.btn.loading.btn-sm:before, .btn.loading.btn-xs:before {
  height: .75rem;
  width: .75rem;
}

.btn-group > input[type="radio"]:checked.btn, .btn-group > .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-group > input[type="radio"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible {
  outline: 2px solid hsl(var(--p));
}

.btn-group:not(.btn-group-vertical) > .btn:not(:first-of-type) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.btn-group:not(.btn-group-vertical) > .btn:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-of-type) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .95));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-title {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.checkbox:focus-visible {
  outline: 2px solid hsl(var(--bc));
  outline-offset: 2px;
}

.checkbox:checked, .checkbox[checked="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-in-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-in-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(-90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(0deg, hsl(var(--chkbg)) 43%, hsl(var(--chkfg)) 43%, hsl(var(--chkfg)) 57%, hsl(var(--chkbg)) 57%);
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  opacity: .2;
  border-color: #0000;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

body[dir="rtl"] .checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
}

body[dir="rtl"] .checkbox:checked, body[dir="rtl"] .checkbox[checked="true"] {
  background-image: linear-gradient(45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(-45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-primary {
  outline: 2px solid hsl(var(--p));
}

.label a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.input-disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.input-disabled::placeholder, .input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.menu.horizontal li.bordered > a, .menu.horizontal li.bordered > button, .menu.horizontal li.bordered > span {
  --tw-border-opacity: 1;
  border-bottom-width: 4px;
  border-left-width: 0;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.menu[class*=" p-"] li > *, .menu[class^="p-"] li > * {
  border-radius: var(--rounded-btn, .5rem);
}

.menu :where(li.bordered > *) {
  --tw-border-opacity: 1;
  border-left-width: 4px;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.menu :where(li) > :where(:not(ul)) {
  color: currentColor;
  gap: .75rem;
  padding: .75rem 1rem;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):focus), .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):hover) {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active), .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.menu :where(li:empty) {
  height: 1px;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  margin: .5rem 1rem;
}

.menu li.disabled > * {
  -webkit-user-select: none;
  user-select: none;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.menu li.disabled > :hover {
  background-color: #0000;
}

.menu li.hover-bordered a {
  border-color: #0000;
  border-left-width: 4px;
}

.menu li.hover-bordered a:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.menu.compact li > a, .menu.compact li > span {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.menu .menu-title > * {
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .4;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.menu :where(li:not(.disabled)) > :where(:not(ul)) {
  outline-offset: 2px;
  outline: 2px solid #0000;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu > :where(li:first-child) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li:last-child) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu > :where(li) > :where(ul) :where(li) {
  width: 100%;
  white-space: nowrap;
}

.menu > :where(li) > :where(ul) :where(li) :where(ul) {
  padding-left: 1rem;
}

.menu > :where(li) > :where(ul) :where(li) > :where(:not(ul)) {
  width: 100%;
  white-space: nowrap;
}

.menu > :where(li) > :where(ul) > :where(li:first-child) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li) > :where(ul) > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li) > :where(ul) > :where(li:last-child) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu > :where(li) > :where(ul) > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

@keyframes progress-loading {
  50% {
    left: 107%;
  }
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.card-compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-compact .card-title {
  margin-bottom: .25rem;
}

.card-normal .card-body {
  padding: var(--padding-card, 2rem);
  font-size: 1rem;
  line-height: 1.5rem;
}

.card-normal .card-title {
  margin-bottom: .75rem;
}

.m-auto {
  margin: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-20 {
  height: 5rem;
}

.h-auto {
  height: auto;
}

.h-screen {
  height: 100vh;
}

.h-96 {
  height: 24rem;
}

.min-h-screen {
  min-height: 100vh;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-40 {
  width: 10rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-96 {
  width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-center {
  place-content: center;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)) );
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-md {
  border-radius: .375rem;
}

.border-t {
  border-top-width: 1px;
}

.border-transparent {
  border-color: #0000;
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-book-now-image {
  background-image: url("massage.6ab9d1a6.jpg");
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-purple-500 {
  --tw-gradient-from: #a855f7;
  --tw-gradient-to: #a855f700;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-blue-500 {
  --tw-gradient-to: #3b82f6;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-current {
  fill: currentColor;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 2rem;
}

.p-4 {
  padding: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.font-bold {
  font-weight: 700;
}

.font-thin {
  font-weight: 100;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.no-underline {
  text-decoration-line: none;
}

.bg-blend-overlay {
  background-blend-mode: overlay;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.center {
  text-align: center;
  width: 100%;
}

#menu-toggle:checked + #menu {
  display: block;
}

.hover\:border-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

@media (prefers-color-scheme: dark) {
  .dark\:block {
    display: block;
  }

  .dark\:hidden {
    display: none;
  }

  .dark\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgb(31 41 55 / var(--tw-border-opacity));
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgb(216 180 254 / var(--tw-text-opacity));
  }

  .dark\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgb(229 231 235 / var(--tw-text-opacity));
  }

  .dark\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .dark\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
}

@media (min-width: 640px) {
  .sm\:flex {
    display: flex;
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px;
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .sm\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
  }
}

@media (prefers-color-scheme: dark) {
  @media (min-width: 640px) {
    .dark\:sm\:hover\:text-purple-700:hover {
      --tw-text-opacity: 1;
      color: rgb(126 34 206 / var(--tw-text-opacity));
    }
  }
}

@media (min-width: 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:gap-12 {
    gap: 3rem;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }
}

@media (min-width: 1280px) {
  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

/*# sourceMappingURL=index.f1fb5bb8.css.map */
